import React,{ useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';


export const Footer = () => {

  const url = "https://backend.odssocialnetwork.org//wp-json/wp/v2/posts?_embed&tags=105&per_page=3";

  const [ posts, setPosts] = useState([]);
  const [ loading, setLoading] = useState(true);


  useEffect(() => {
      fetch(url)
        .then(response => response.json())
        .then(posts => {
            console.log(posts);
            setPosts(posts);
            setLoading(false);
      })},[]);


    return (
    <>

    <div className="shortcode-html">
            {/* Footer */}
            <div className="g-bg-black-opacity-0_9 g-color-white-opacity-0_8 g-py-60">
              <div className="container">
                <div className="row">
                  {/* Footer Content */}
                  <div className="col-lg-3 col-md-6 g-mb-40 g-mb-0--lg">
                    <a className="d-block g-mb-20" href="index.html">
                      <img className="img-fluid" src="/logo-ods.png" alt="Logo"/>
                    </a>
                    <p></p>
                  </div>
                  {/* End Footer Content */}

                  {/* Footer Content */}
                  <div className="col-lg-3 col-md-6 g-mb-40 g-mb-0--lg">
                    <div className="u-heading-v3-1 g-brd-white-opacity-0_3 g-mb-25">
                      <h2 className="u-heading-v3__title h6 text-uppercase g-brd-primary">Últimas noticias</h2>
                    </div>

                    {
                      posts.map(post => (
                        <>
                        <article>
                          <h3 className="h6 g-mb-2">
                          <Link to={"/noticia/" + posts[1].id} className="g-color-white-opacity-0_8 g-color-white--hover">{post.title.rendered}</Link>
                          </h3>
                          <div className="small g-color-white-opacity-0_6"><Moment locale="es">{posts.date}</Moment></div>
                        </article>
                        <hr className="g-brd-white-opacity-0_1 g-my-10"/>
                        </>

                      ))
                    }
                   
                  </div>
                  {/* End Footer Content */}

                  {/* Footer Content */}
                  <div className="col-lg-3 col-md-6 g-mb-40 g-mb-0--lg">
                    <div className="u-heading-v3-1 g-brd-white-opacity-0_3 g-mb-25">
                      <h2 className="u-heading-v3__title h6 text-uppercase g-brd-primary">Enlaces</h2>
                    </div>

                    <nav className="text-uppercase1">
                      <ul className="list-unstyled g-mt-minus-10 mb-0">
                        <li className="g-pos-rel g-brd-bottom g-brd-white-opacity-0_1 g-py-10">
                          <h4 className="h6 g-pr-20 mb-0">
                            <a className="g-color-white-opacity-0_8 g-color-white--hover" href="/info">Intro</a>
                            <i className="fa fa-angle-right g-absolute-centered--y g-right-0"></i>
                          </h4>
                        </li>
                        <li className="g-pos-rel g-brd-bottom g-brd-white-opacity-0_1 g-py-10">
                          <h4 className="h6 g-pr-20 mb-0">
                            <a className="g-color-white-opacity-0_8 g-color-white--hover" href="/ong">Líderes ODS</a>
                            <i className="fa fa-angle-right g-absolute-centered--y g-right-0"></i>
                          </h4>
                        </li>
                        <li className="g-pos-rel g-brd-bottom g-brd-white-opacity-0_1 g-py-10">
                          <h4 className="h6 g-pr-20 mb-0">
                            <a className="g-color-white-opacity-0_8 g-color-white--hover" href="/odsinfo">ODS Info</a>
                            <i className="fa fa-angle-right g-absolute-centered--y g-right-0"></i>
                          </h4>
                        </li>
                        <li className="g-pos-rel g-brd-bottom g-brd-white-opacity-0_1 g-py-10">
                          <h4 className="h6 g-pr-20 mb-0">
                            <a className="g-color-white-opacity-0_8 g-color-white--hover" href="/noticias">Noticias</a>
                            <i className="fa fa-angle-right g-absolute-centered--y g-right-0"></i>
                          </h4>
                        </li>
                        <li className="g-pos-rel g-py-10">
                          <h4 className="h6 g-pr-20 mb-0">
                            <a className="g-color-white-opacity-0_8 g-color-white--hover" href="/ple">PLE</a>
                            <i className="fa fa-angle-right g-absolute-centered--y g-right-0"></i>
                          </h4>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  {/* End Footer Content */}

                  {/* Footer Content */}
                  <div className="col-lg-3 col-md-6">
                    <div className="u-heading-v3-1 g-brd-white-opacity-0_3 g-mb-25">
                      <h2 className="u-heading-v3__title h6 text-uppercase g-brd-primary">Contacto</h2>
                    </div>

                    <address className="g-bg-no-repeat g-line-height-2 g-mt-minus-4" style={{BackgroundImage: "url(../../../assets/img/maps/map2.png)"}}>
                    Fundación Ciudadanía,
                      <br/>
                      Ctra. Sevilla 8
                      <br/>
                      06810, Calamonte, Badajoz, SPAIN
                      <br/>
                      Tel: +34 699 440 731
                      <br/>
                      Email:
                      <a href="mailto:contacto@fundacionciudadania.es" className=""> contacto@fundacionciudadania.es</a>
                      <a href="https://www.fundacionciudadania.es/"><img src="/assets/img/logo/LogoFCblanco.png" width={200}/></a>

                    </address>

                  </div>
                  {/* End Footer Content */}
                </div>
              </div>
            </div>
            {/* End Footer */}

            {/* Copyright Footer */}
            <footer className="g-bg-gray-dark-v1 g-color-white-opacity-0_8 g-py-20">
              <div className="container">
                <div className="row">
                  <div className="col-md-8 text-center text-md-left g-mb-15 g-mb-0--md">
                    <div className="d-lg-flex">
                      <small className="d-block g-font-size-default g-mr-10 g-mb-10 g-mb-0--md">2020 © All Rights Reserved.</small>
                      <ul className="u-list-inline">
                        <li className="list-inline-item">
                          <Link to="/aviso_legal">Aviso legal</Link>
                        </li>
                        <li className="list-inline-item">
                          <span>|</span>
                        </li>
                        <li className="list-inline-item">
                          <a href="/politica_de_privacidad">Política de privacidad</a>
                        </li>
                        <li className="list-inline-item">
                          <span>|</span>
                        </li>
                        <li className="list-inline-item">
                          <a href="/politica_de_cookies">Política de Cookies</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-4 align-self-center">
                    <ul className="list-inline text-center text-md-right mb-0">
                      <li className="list-inline-item g-mx-10" data-toggle="tooltip" data-placement="top" title="Facebook">
                        <a href="https://www.facebook.com/ODSSocialNetwork" className="g-color-white-opacity-0_5 g-color-white--hover">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                    {/*}  <li className="list-inline-item g-mx-10" data-toggle="tooltip" data-placement="top" title="Skype">
                        <a href="#" className="g-color-white-opacity-0_5 g-color-white--hover">
                          <i className="fa fa-skype"></i>
                        </a>
                      </li>
                      <li className="list-inline-item g-mx-10" data-toggle="tooltip" data-placement="top" title="Linkedin">
                        <a href="#" className="g-color-white-opacity-0_5 g-color-white--hover">
                          <i className="fa fa-linkedin"></i>
                        </a>
                      </li>
                      <li className="list-inline-item g-mx-10" data-toggle="tooltip" data-placement="top" title="Pinterest">
                        <a href="#" className="g-color-white-opacity-0_5 g-color-white--hover">
                          <i className="fa fa-pinterest"></i>
                        </a>
                      </li> */}
                      <li className="list-inline-item g-mx-10" data-toggle="tooltip" data-placement="top" title="Twitter">
                        <a href="https://twitter.com/ODSSocialNetWo1" className="g-color-white-opacity-0_5 g-color-white--hover">
                          <i className="fa fa-twitter"></i>
                        </a>
                  </li> 
                  {/*}
                      <li className="list-inline-item g-mx-10" data-toggle="tooltip" data-placement="top" title="Dribbble">
                        <a href="#" className="g-color-white-opacity-0_5 g-color-white--hover">
                          <i className="fa fa-dribbble"></i>
                        </a>
                    </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </footer>
            {/* End Copyright Footer */}
          </div>

          <div className="shortcode-scripts">
            {/* JS Global Compulsory */}
            <script src="../../../assets/vendor/popper.js/popper.min.js"></script>
          </div>
        
    </>
    )
}