import GoogleMapReact from 'google-map-react';
import React, { useEffect } from 'react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export const Contacto = () => {

    const defaultProps = {
        center: {
          lat: 38.885178,
          lng: -6.379326
        },
        zoom: 11
      };

      const scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
     };

     useEffect( () => {
      scrollTop();
     },[]);

    return (
        <>

       <section class="g-bg-gray-dark-v1">
        <div class="container g-color-white g-py-150">
          <h2 class="display-4 g-font-weight-600 text-uppercase">Contacta con nosotros</h2>
          <h3 class="h2 g-font-weight-300 mb-0">¿Alguna pregunta o sugerencia?</h3>
        </div>
      </section>
        <section class="clearfix">
        <div class="row no-gutters">
          <div class="col-md-6 align-self-center js-g-map embed-responsive g-height-100vh">
          <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDK-e1YeKdJJcmMPJt0eDz1ef-dn35t7wA" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent
            lat={38.885178}
            lng={-6.379326}

            
            text="ODS Iberoamérica"
          />
        </GoogleMapReact>
        
          </div>
  
          <div class="col-md-6 align-self-center">
            <div class="g-pa-100">
              <div class="g-mb-60">
                <h2 class="h1 g-color-black">Contacto</h2>
                <p class="g-color-gray-dark-v3 g-font-size-16">Construyamos un mundo nuevo. Si tienes cualquier duda, si quieres aportar, colaborar, cooperar…

                No dudes en contactar!!.</p>
              </div>
  
              <div class="mb-5">
                <h2 class="h4 g-color-black">ODS Iberoamérica.</h2>
                <p class="g-color-gray-dark-v3 g-font-size-16 mb-0">Fundación Ciudadanía, Ctra. Sevilla, 8, 06810</p>
                <p class="g-color-gray-dark-v3 g-font-size-16 mb-0">contacto@fundacionciudadania.es</p>
              </div>
  
              <a class="btn u-btn-black g-font-weight-600 g-font-size-12 text-uppercase rounded0 g-py-15 g-py-13 g-px-40" href="index.html">Contactar</a>
            </div>
          </div>
        </div>
      </section>
      </>
    )
}