export const Breadcrumb = ({info1, info2, info3 , camino}) => {

    const imagen = {
        backgroundImage: "url(../../assets/img-temp/1920x800/fondo-cabecera.jpg)"
    }

    return (

        <div className="shortcode-html">
        <section className="g-bg-size-cover g-bg-pos-center g-bg-cover g-bg-black-opacity-0_5--after g-color-white g-py-100 g-mb-10" style={imagen}>
          <div className="container g-bg-cover__inner">
            <header className="g-mb-20">
              <h3 className="h5 g-font-weight-300 g-mb-5">ODS Iberoamérica</h3>
              <h2 className="h1 g-font-weight-300 text-uppercase">{info1}
                <span className="g-color-primary">{info2}</span>
                {info3}
              </h2>
            </header>

            <ul className="u-list-inline">
              <li className="list-inline-item g-mr-7">
                <a className="u-link-v5 g-color-white g-color-primary--hover" href="#">Inicio</a>
                <i className="fa fa-angle-right g-ml-7"></i>
              </li>
              <li className="list-inline-item g-color-primary">
                <span>{camino}</span>
              </li>
            </ul>
          </div>
        </section>
      </div>
    
    )
}