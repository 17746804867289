import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import MapChart from "./MapChart";
import ReactTooltip from "react-tooltip";
import { Breadcrumb } from '../layout/Breadcrumb';
import { Terminos } from '../layout/Terminos';
import { Botones } from './Botones';
import { csv } from "d3-fetch";



export const ODSMundo = () => {

    const [content, setContent] = useState("");
    const [data, setData] = useState([]);
    const [ods, setOds] = useState("Normalized Score: sdg1_wpc");
    const [nombre, setNombre] = useState("ODS 01");

    useEffect(() => {
      csv(`/datos/mapa/SDR2020Database/SDR2023 Data-Tabla 2.csv`).then((data) => {
        setData(data);
        console.log(data);
      });
    }, [ods]);


    const cambioDatos = (datosmapa,nombre) => {
      setOds(datosmapa);
      setNombre(nombre);

    }


    return (
        <>
          <Breadcrumb
            info1 = "Mundo "
            info2 = "ODS"
            camino = "MundoODS"
          />
          <Terminos
          titulo="Mundo ODS"
          texto={nombre}
          />
          {/* Content */}
          <section>
            <div class="container">
              <div class="row">
                <div className="col-2">
                <Botones 
                  cambioDatos={cambioDatos}
                />
                </div>
                <div className="col-10">
                    <MapChart 
                      setTooltipContent={setContent} 
                      data={data}
                      ods={ods}
                    
                    />
                    <ReactTooltip>{content}</ReactTooltip>
                    </div>
              </div>
            </div>
          </section>
          {/* Content */}

        </>
    )
}