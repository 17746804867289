import React from 'react'

export const Item1 = () => {
    return (
        <section className="container u-block-hover--scale g-min-height-200 g-flex-middle g-bg-cover g-bg-size-cover g-bg-bluegray-opacity-0_3--after g-transition-0_5" style={{backgroundImage: "url(../../assets/img-temp/1920x1080/img20.jpg)"}}>
          <div className="container g-pos-rel g-z-index-1 g-py-150">
            <div className="g-mb-40">
              <h1 className="g-color-white g-font-weight-700 g-font-size-80 g-font-size-130--md g-line-height-1_2 mb-0">ODS</h1>
              <span className="d-block g-color-white g-font-size-50 g-pos-rel g-top-minus-20--md ml-md-20">Iberoamérica</span>
            </div>
          </div>
        </section>
    )
}
