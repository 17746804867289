import { Breadcrumb } from "../layout/Breadcrumb"
import StickyBox from "react-sticky-box";

export const Info = () => {
    return (
        <>
        <Breadcrumb
            info1 = "Información"
            camino = "info"
        />

    {/* Blog Single Item Blocks */}
    <div className="container-fluid px-0">
      <div className="row no-gutters">
        <div className="col-md-6">
            <StickyBox >
          <img src="/assets/img-temp/900x900/cara.jpg"  width="100%" object-fit="cover"/>
          </StickyBox>
        </div>


        <div className="col-md-6">
          <div className="g-pa-50 g-pt-0 g-px-100--lg g-pb-100--lg">
            <div className="g-max-width-400--md g-pos-rel g-z-index-1 g-pt-100 g-pl-50 g-pl-0--md g-ml-minus-50--lg mb-4">
              <h1 className="h2 g-color-black g-font-weight-600">¿Qué es ODS Iberoamérica?</h1>
            </div>

            <div className="mb-5">
              <p className="g-color-darkred g-font-weight-600 g-font-size-16">Es una plataforma que apuesta por dotarnos de un espacio dinámico, activo y en permanente actualización que facilite el acceso a herramientas, materiales y recursos que se están generando  sobre los Objetivos de Desarrollo Sostenible.</p>
              <p className="g-color-darkred g-font-weight-600 g-font-size-16">Es un espacio colaborativo de referencia para todos los agentes que trabajan por la consecución de los ODS en el mundo.</p>

            </div>

      
            <div className="g-max-width-400--md g-pos-rel g-z-index-1 g-pt-100 g-pl-50 g-pl-0--md g-ml-minus-50--lg mb-4">
              <h1 className="h2 g-color-black g-font-weight-600">¿Qué pretende?</h1>
            </div>
              <p className="g-color-darkgrey g-font-weight-600 g-font-size-16">Existen numerosas fuentes de información acerca de los ODS; información de calidad, pero dispersa, desaprovechando en muchas ocasiones las experiencias de entidades vecinas.</p>
              <p className="g-color-darkgrey g-font-weight-600 g-font-size-16">ODS Iberoamérica, pretende centralizar los recursos del territorio en un mismo espacio de colaboración, de forma que los agentes participantes puedan desarrollar sinergias a la hora de crear y desarrollar sus proyectos. En definitiva, queremos facilitar alianzas entre las entidades que trabajan para conseguir los Objetivos de Desarrollo Sostenible.</p>

              <div className="g-max-width-400--md g-pos-rel g-z-index-1 g-pt-100 g-pl-50 g-pl-0--md g-ml-minus-50--lg mb-4">
              <h1 className="h2 g-color-black g-font-weight-600">Cómo funciona.</h1>
            </div>
            <p className="g-color-darkred g-font-weight-600 g-font-size-16">ODS Iberoamérica se articula en torno a tres ejes orientados a que toda la ciudadanía pueda elevar su grado de participación y compromiso con el Desarrollo Sostenible:</p>
            <p className="g-color-darkgrey g-font-weight-600 g-font-size-16">Es un espacio para la <strong>gestión del conocimiento</strong>, con recursos  relacionados con los Objetivos de Desarrollo Sostenible que abarcan legislación, informes, audiovisuales, material didáctico, entre otros.</p>
            <p className="g-color-darkgrey g-font-weight-600 g-font-size-16">Es un espacio de <strong>aprendizaje</strong>, con más de 6000 horas de cursos, gratuitos en su mayor parte, acerca de los ODS organizados en función de las necesidades e intereses de cada persona.</p>
            <p className="g-color-darkgrey g-font-weight-600 g-font-size-16">Es un espacio de <strong>participación colaborativa</strong>, en el que las entidades que trabajan en Desarrollo Sostenible compartan sus experiencias y proyectos, así como una sección de CUENTANODS para elaborar cuentos colaborativos sobre los ODS.</p>
            <p className="g-color-darkgrey g-font-weight-600 g-font-size-16">Es un espacio de <strong>comunicación</strong> con noticias y actualidad acerca de los ODS.</p>
            <p className="g-color-darkgrey g-font-weight-600 g-font-size-16"><strong>así como un calendario de las actividades desarrolladas por las entidades del territorio, maximizando la visibilidad de éstas.</strong></p>

            <div className="g-max-width-400--md g-pos-rel g-z-index-1 g-pt-100 g-pl-50 g-pl-0--md g-ml-minus-50--lg mb-4">
              <h1 className="h2 g-color-black g-font-weight-600">Participación</h1>
            </div>
            <p className="g-color-darkgrey g-font-weight-600 g-font-size-16">La esencia de <strong>ODS Iberoamérica es colaborativa</strong>, y para fomentar esa colaboración, ofrecemos a las entidades que quieran participar un escaparate para ello.</p>

          
         
            <div className="g-mb-60" >
              <p>En virtud de su centralidad y permanente actualización de información, los agentes pueden dar a conocer sus proyectos, actividades o noticias, de manera que llegue a toda la red de actores que trabajamos por los ODS, fomentando alianzas y sinergias junto a experiencias de terceras entidades, o tomar parte activa de la formación compartiendo vuestros propios recursos.</p>

          
            </div>

            {/* Social Icons */}
            {/*<div className="g-brd-top g-brd-gray-light-v4 text-center g-pt-50">
              <h3 className="h6 g-color-black g-font-weight-600 text-uppercase mb-3">Share:</h3>
              <ul className="list-inline mb-0">
                <li className="list-inline-item g-mx-2">
                  <a className="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-facebook--hover" href="#">
                    <i className="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-facebook"></i>
                    <i className="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-facebook"></i>
                  </a>
                </li>
                <li className="list-inline-item g-mx-2">
                  <a className="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-twitter--hover" href="#">
                    <i className="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-twitter"></i>
                    <i className="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item g-mx-2">
                  <a className="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-google-plus--hover" href="#">
                    <i className="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-google-plus"></i>
                    <i className="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-google-plus"></i>
                  </a>
                </li>
                <li className="list-inline-item g-mx-2">
                  <a className="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-linkedin--hover" href="#">
                    <i className="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-linkedin"></i>
                    <i className="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>*/}
            {/* End Social Icons */}
          </div>
        </div>
      </div>
    </div>
    {/* End Blog Single Item Blocks */}

        </>
    )
}